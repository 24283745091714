<template>
    <CRow v-if="can('view_dashboard_tender_statistics')" class="justify-content-center">
        <CCol>
            <CRow
                v-if="spinner.tendersCSV"
                style="position: fixed;
             left: 0;
             top: 0;
             width: 100%;
             height: 100%;
             z-index: 9999;
             text-align: center;
             padding-top: 100px;
             background-color: rgba(0,0,0,0.7)" class="justify-content-center">
                <CCol style="margin: auto;">
                    <div class="px-5 align-items-center">
                        <CSpinner color="primary" style="width: 8rem; height: 8rem; font-size: 25px;"/>
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow class="py-3 justify-content-center">

                                        <CCol col="12" sm="6" md="6" lg="3" xl="2" class="py-1">
                                            <h2>Дашборд</h2>
                                        </CCol>
                                        <CCol col="12" sm="6" md="6" lg="3" class="py-1">
                                            <multiselect
                                                :show-labels="false"
                                                track-by="id"
                                                placeholder="Виберіть регіон"
                                                :options="getRegions"
                                                v-model="dashboard.region"
                                                label="region"
                                            ></multiselect>
                                        </CCol>

                                        <CCol col="12" sm="6" md="6" lg="2" class="py-1">
                                            <vc-date-picker
                                                style="z-index: 9999;"
                                                required
                                                class="pb-1"
                                                locale="uk"
                                                :popover="{placement: 'bottom', visibility: 'click'}"
                                                v-model="dashboard.start_date"
                                                color="gray"
                                                :max-date="new Date(Date.now())"
                                            >
                                                <template v-slot="{inputValue, inputEvent}">
                                                    <input
                                                        class="w-100 py-2 px-2"
                                                        :value="inputValue"
                                                        v-on="inputEvent"
                                                        masks="DD.MM.YYYY"
                                                    />
                                                </template>
                                            </vc-date-picker>
                                        </CCol>
                                        <CCol col="12" sm="6" md="6" lg="2" class="py-1">
                                            <vc-date-picker
                                                class="pb-1"
                                                style="z-index: 9999;"
                                                required
                                                locale="uk"
                                                :popover="{placement: 'bottom', visibility: 'click'}"
                                                v-model='dashboard.end_date'
                                                color="gray"
                                                :max-date="new Date(Date.now())"
                                            >
                                                <template v-slot="{inputValue, inputEvent}">
                                                    <input
                                                        class="w-100 py-2 px-2"
                                                        :value="inputValue"
                                                        v-on="inputEvent"
                                                        masks="DD.MM.YYYY"
                                                    />
                                                </template>
                                            </vc-date-picker>
                                        </CCol>
                                <CCol col="12" sm="2" class="text-center">
                                    <CButton class="px-4 py-2" color="success" @click="getStatistics">Застосувати</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="12" lg="6" class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Тендери</h3>
                                    <p>Загальна к-сть: {{summarized}}</p>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/orders/csv`,'Status_tenders')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.tendersDatacolection">
                                <CChartDoughnut
                                    :width="200"
                                    :height="200"
                                    :options="datacollection.options"
                                    :datasets="datacollection.datasets"
                                    :labels="datacollection.labels"
                                />
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                        <CCardFooter>
                            <CRow>
                                <CCol col="12" sm="5" class="px-1">
                                    <multiselect
                                        required
                                        class="pb-1"
                                        :show-labels="false"
                                        placeholder="Призначення"
                                        invalid-feedback="Виберіть призначення"
                                        :is-valid="isChannelPurpose(dashboard.channel_purpose)"
                                        :options="['Звичайний', 'МКЦ', 'Агрегуючий','Магістральний']"
                                        v-model="dashboard.channel_purpose"
                                    ></multiselect>
                                </CCol>
                                <CCol col="12" sm="5" class="px-1">
                                    <multiselect
                                        required
                                        class="pb-1"
                                        :show-labels="false"
                                        placeholder="Вид канал"
                                        invalid-feedback="Виберіть вид каналу"
                                        :is-valid="isConsumerType(dashboard.consumer_type)"
                                        :options="dashboard.channel_purpose !== '' && dashboard.channel_purpose !== null && dashboard.channel_purpose !== 'Звичайний' ? optionsTenderWithOne : optionsTenderWithTwo"
                                        v-model="dashboard.consumer_type"
                                    ></multiselect>
                                </CCol>
                                <CCol col="12" sm="2" class="px-1 text-right">
                                    <CButton
                                        color="danger"
                                        class="py-2 px-1"
                                        @click="clearTenderSelect()">Очистити</CButton>
                                </CCol>
                            </CRow>
                        </CCardFooter>
                    </CCard>
                </CCol>
                <CCol col="12" lg="6" class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Якість заявок</h3>
                                    <p>Загальна к-сть: {{correctTenders.all_count}}</p>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/quality/csv`,'Number_of_corrected_tenders')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.correctTendersDatacolection">
                                <CChartDoughnut
                                    :options="datacollectionCorrectTenders.options"
                                    :datasets="datacollectionCorrectTenders.datasets"
                                    :labels="datacollectionCorrectTenders.labels"/>
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                        <CCardFooter class="pb-3">
                            <br>
                            <br>
                        </CCardFooter>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Кількість провайдерів</h3>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/providers/csv`,'Number_Providers')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.NumberProvidersDatacolection">
                                <CChartBar
                                    style="height:80vh"
                                    :datasets="datacollectionNumberProviders.datasets"
                                    :labels="datacollectionNumberProviders.labels"
                                    :options="datacollectionNumberProviders.options"/>
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol class="p-1">
                    <CCard>
                        <CCardBody>
                            <CRow class="justify-content-center">
                                <CCol col="12" sm="3" lg="3" xl="3" class="px-1">
                                    <multiselect
                                        required
                                        class="pb-1"
                                        :show-labels="false"
                                        placeholder="Призначення"
                                        invalid-feedback="Виберіть призначення"
                                        :is-valid="isChannelPurpose(dashboard.channel_purpose)"
                                        :options="['Звичайний', 'МКЦ', 'Агрегуючий','Магістральний']"
                                        v-model="dashboardNew.channel_purpose"
                                    ></multiselect>
                                </CCol>
                                <CCol col="12" sm="4" lg="3" xl="3" class="px-1">
                                    <multiselect
                                        required
                                        class="pb-1"
                                        :show-labels="false"
                                        placeholder="Вид канал"
                                        invalid-feedback="Вид каналу"
                                        :is-valid="isConsumerType(dashboard.consumer_type)"
                                        :options="dashboardNew.channel_purpose !== '' && dashboardNew.channel_purpose !== null && dashboardNew.channel_purpose !== 'Звичайний' ? optionsTenderWithOne : optionsTenderWithTwo"
                                        v-model="dashboardNew.consumer_type"
                                    ></multiselect>
                                </CCol>
                                <CCol col="12" sm="3" lg="3" xl="3" class="px-1">
                                    <multiselect
                                        required
                                        class="pb-1"
                                        :show-labels="false"
                                        placeholder="Тип каналу"
                                        invalid-feedback="Виберіть тип каналу"
                                        :is-valid="isConsumerType(dashboard.consumer_type)"
                                        :options="['КПД L2','Інтернет']"
                                        v-model="dashboardNew.connect_type"
                                    ></multiselect>
                                </CCol>
                                <CCol col="12" sm="2" lg="1" class="px-1 text-right">
                                    <CButton
                                        color="danger"
                                        class="py-2 px-1"
                                        @click="clearCostSelect()">Очистити</CButton>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="12" lg="6" class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Активність провайдерів Топ - 10</h3>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/offers/csv`,'Providers_Activity')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.providerActivityDatacolection">
                                <CChartBar
                                    :datasets = "datacollectionProviderActivity.datasets"
                                    :labels = "datacollectionProviderActivity.labels"
                                    :options = "datacollectionProviderActivity.options"
                                />
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol col="12" lg="6" class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Вартість підключення</h3>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/cost/csv`,'connection_cost')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.costConnectionDatacolection">
                                <CChartBar
                                    :datasets="datacollectionCostConnection.datasets"
                                    :labels="datacollectionCostConnection.labels"
                                    :options="datacollectionCostConnection.options"
                                />
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
            <CRow>
                <CCol col="12" lg="6" class="p-1" >
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Вартість абонплати (канал)</h3>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/cost/csv`,'Subscription_Cost')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.tendersCostSubscription">
                                <CChartBar
                                    :datasets="datacollectionCostSubscription.datasets"
                                    :labels="datacollectionCostSubscription.labels"
                                    :options="datacollectionCostSubscription.options"
                                />
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                    </CCard>
                </CCol>
                <CCol col="12" lg="6" class="p-1">
                    <CCard>
                        <CCardHeader>
                            <CRow>
                                <CCol col="12" md="8">
                                    <h3>Вартість абонплати на 1Мбіт</h3>
                                </CCol>
                                <CCol col="12" md="4" class="text-right">
                                    <CButton
                                        variant="outline"
                                        color="success"
                                        @click="getTendersCVS(`/api/statistics/cost/csv`,'Subscription_Cost_For_1_Mbit')"
                                    >Вигрузити CSV</CButton>
                                </CCol>
                            </CRow>
                        </CCardHeader>
                        <CCardBody>
                            <span v-if="!spinner.tendersCostSubscriptionFor1Mbit">
                                <CChartBar
                                    :datasets="datacollectionCostSubscriptionFor1Mbit.datasets"
                                    :labels="datacollectionCostSubscriptionFor1Mbit.labels"
                                    :options="datacollectionCostSubscriptionFor1Mbit.options"
                                />
                            </span>
                            <span v-else>
                                <CRow class="justify-content-center">
                                    <div class="align-items-center ">
                                        <CSpinner color="primary" style="width: 8rem; height: 8rem;"/>
                                    </div>
                                </CRow>
                            </span>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>

<script>

import {  CChartDoughnut,CChartBar, } from '@coreui/vue-chartjs'
import axios from "axios";
import Multiselect from 'vue-multiselect'
import timeConvertMixin from '../../mixins/timeConvertMixin'
import statusTenderAndPropositionMixin from "@/mixins/statusTenderAndPropositionMixin";
import shortName from "@/mixins/shortName";
import {can} from "@/rules/permissions";

export default {
    mixins:[timeConvertMixin,statusTenderAndPropositionMixin,shortName],
    components:{
        CChartDoughnut,
        CChartBar,
        Multiselect,
    },
    name: "TenderStatistics",
    data(){
        return{
            correctTenders:{},
            datacollection: [],
            datacollectionCorrectTenders: [],
            datacollectionNumberProviders: [],
            datacollectionProviderActivity: [],
            datacollectionCostConnection: [],
            datacollectionCostSubscription:[],
            datacollectionCostSubscriptionFor1Mbit:[],
            getRegions: [],
            tenderStatistic:[],
            summarized:0,
            dashboard:{
                start_date:'',
                end_date:'',
                region:'',
                channel_purpose:'',
                consumer_type:'',
            },
            dashboardNew:{
                channel_purpose:'',
                consumer_type:'',
                connect_type:'',
            },
            optionsTenderWithOne:['Внутрішній (відділення)'],
            optionsTenderWithTwo:['Зовнішній (АТМ/ТСО/РОS)', 'Внутрішній (відділення)'],
            spinner: {
                tendersCSV: false,
                providerActivity: false,
                tendersDatacolection: false,
                correctTendersDatacolection: false,
                NumberProvidersDatacolection: false,
                providerActivityDatacolection: false,
                costConnectionDatacolection: false,
                tendersCostSubscription: false,
                tendersCostSubscriptionFor1Mbit: false,
            }
        }
    },
    mounted () {
        this.getStartDate();
        this.fillData();
        this.getRegion();
        this.getTendersStatus();
        this.getCorrectionTenders();
        this.getNumberProviders();
        this.getProviderActivity();
        this.getCostGrafics();
    },
    methods: {
        can,
        clearTenderSelect(){
            this.dashboard.channel_purpose = "";
            this.dashboard.consumer_type = "";
        },

        clearCostSelect(){
            this.dashboardNew.channel_purpose = "";
            this.dashboardNew.consumer_type = "";
            this.dashboardNew.connect_type = "";
        },

        getCostGrafics(){
            this.getCostConnection();
            this.getCostSubscription();
            this.getCostSubscriptionFor1Mbit();
        },

        getStartDate(){
            const date = new Date();
            this.dashboard.end_date = new Date()
            this.dashboard.start_date=new Date(date.setMonth(date.getMonth() - 1));
        },

        getRegion(){
            let self = this;
            axios.get(`/api/regions`).then(function (response) {
                self.getRegions = response.data;
            }).catch(function () {})
        },

        getStatistics(){
            this.dashboard.channel_purpose='';
            this.dashboard.consumer_type='';
            this.dashboardNew.channel_purpose='';
            this.dashboardNew.connect_type='';
            this.dashboardNew.consumer_type='';
            this.getTendersStatus();
            this.getCorrectionTenders();
            this.getNumberProviders();
            this.getProviderActivity();
            this.getCostGrafics();
        },

        getTendersCVS(link,name){
            let self=this;
            link = self.setLinkQuery(link,name)
            self.spinner.tendersCSV=true;
            axios({url: link,
                method: 'GET',
                responseType:'blob'
            }).then(function(response){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${name}.csv`); //or any other extension
                document.body.appendChild(link);
                link.click();
                self.spinner.tendersCSV=false;
            }).catch(function (){self.spinner.tendersCSV=false;});
        },

        isChannelPurpose(val){
            return val !== "";

            // if (val === ""){
            //     return false;
            // }
            // return true;
        },

        isConsumerType(val){
            return val !== "";

            // if (val === ""){
            //     return
            // }
            // return true;
        },

        getNumberProviders(){
            let self=this;

            self.spinner.NumberProvidersDatacolection=true;

            this.datacollectionNumberProviders.labels=[]
            this.datacollectionNumberProviders.datasets[0].data=[]
            axios.get(`/api/statistics/providers`).then(function (response){

                self.spinner.NumberProvidersDatacolection=false;

                response.data.map(function (item){
                    self.datacollectionNumberProviders.datasets[0].data.push(item.count)
                    self.datacollectionNumberProviders.labels.push(item.region_name)
                });
            }).catch(function (){
                self.spinner.NumberProvidersDatacolection = false;
            });
        },

        getProviderActivity(){
            let self = this;

            self.spinner.providerActivityDatacolection=true;

            self.datacollectionProviderActivity.labels=[];
            self.datacollectionProviderActivity.datasets[0].data = [];
            self.datacollectionProviderActivity.datasets[1].data = [];
            self.datacollectionProviderActivity.datasets[2].data = [];

            let link=`/api/statistics/offers`;
            link=self.setLinkQuery(link,'Providers_Activity');

            axios.get(link).then(function(response){

                self.spinner.providerActivityDatacolection=false;

                response.data.map(function(item){
                    if (item.brand !== ''){
                        self.datacollectionProviderActivity.labels.push(item.brand);
                    }else{
                        self.datacollectionProviderActivity.labels.push(self.shortNameProvider(item.name));
                    }
                    self.datacollectionProviderActivity.datasets[0].data.push(item.all_provider_offers);
                    self.datacollectionProviderActivity.datasets[1].data.push(item.count_winner);
                    self.datacollectionProviderActivity.datasets[2].data.push(item.count_no_technical_possibility);
                });
            }).catch(function (){
                self.spinner.providerActivityDatacolection=true;
            });
        },

        setLinkQuery(link, id){
            let self=this;

            let dash = [];
            let show_connect_type=false;
            let show_consumer_type=false;
            let show_channel_purpose=false;

            if (['Subscription_Cost','Subscription_Cost_For_1_Mbit','connection_cost'].indexOf(id) !== -1){
                dash = self.dashboardNew
                show_connect_type = true;
                show_consumer_type=true;
                show_channel_purpose=true;
            }
            if (['Number_Providers','Number_of_corrected_tenders','Providers_Activity'].indexOf(id) !== -1){
                show_connect_type = false;
                show_consumer_type=false;
                show_channel_purpose=false;
            }
            if (['Status_tenders'].indexOf(id) !== -1){
                dash = self.dashboard
                show_connect_type = false;
                show_consumer_type=true;
                show_channel_purpose=true;
            }

            let startDate = Number(self.timeUnix(self.dashboard.start_date));
            startDate=this.timeConvertWithoutHours(startDate);

            let endDate = Number(self.timeUnix(self.dashboard.end_date));
            endDate=this.timeConvertWithoutHours(endDate);

            let channel_purpose = '';
            if (show_channel_purpose){
                channel_purpose = encodeURIComponent(dash.channel_purpose);
            }

            let consumer_type = '';
            if (show_consumer_type){
                consumer_type = encodeURIComponent(dash.consumer_type);
            }

            let connect_type ='';
            if (show_connect_type){
                connect_type = encodeURIComponent(dash.connect_type);
            }

            link += `?start_date=${startDate}&end_date=${endDate}`;

            if (show_channel_purpose){
                if (dash.channel_purpose){
                    link += `&channel_purpose=${channel_purpose}`;
                }
            }
            if (show_consumer_type){
                if (dash.consumer_type){
                    link += `&consumer_type=${consumer_type}`;
                }
            }
            if (show_connect_type){
                if (dash.connect_type){
                    link += `&connect_type=${connect_type}`;
                }
            }

            if (self.dashboard.region.id){
                link += `&region=${self.dashboard.region.id}`;
            }

            return link;
        },

        getCostConnection(){
            let self=this;
            self.spinner.costConnectionDatacolection=true;

            self.datacollectionCostConnection.labels=[];
            self.datacollectionCostConnection.datasets[0].data = [];
            self.datacollectionCostConnection.datasets[1].data = [];
            self.datacollectionCostConnection.datasets[2].data = [];

            let link=`/api/statistics/connection_cost`;

            link=self.setLinkQuery(link,'connection_cost');
            axios.get(link).then(function(response){

                self.spinner.costConnectionDatacolection=false;

                response.data.map(function(item){
                    self.datacollectionCostConnection.labels.push(item.region_name);
                    self.datacollectionCostConnection.datasets[0].data.push(item.min_cost);
                    self.datacollectionCostConnection.datasets[1].data.push(item.max_cost);
                    self.datacollectionCostConnection.datasets[2].data.push(item.avg_cost);

                });
            }).catch(function(){
                self.spinner.costConnectionDatacolection=false;
            });
        },

        getCostSubscription(){
            let self = this;
            self.spinner.tendersCostSubscription=true;

            self.datacollectionCostSubscription.labels=[];
            self.datacollectionCostSubscription.datasets[0].data = [];
            self.datacollectionCostSubscription.datasets[1].data = [];
            self.datacollectionCostSubscription.datasets[2].data = [];

            let link=`/api/statistics/monthly_payment`;

            link=self.setLinkQuery(link,'Subscription_Cost');

            axios.get(link).then(function (response){

                self.spinner.tendersCostSubscription=false;

                response.data.map(function (item){
                    self.datacollectionCostSubscription.labels.push(item.region_name)
                    self.datacollectionCostSubscription.datasets[0].data.push(item.min_cost)
                    self.datacollectionCostSubscription.datasets[1].data.push(item.max_cost)
                    self.datacollectionCostSubscription.datasets[2].data.push(item.avg_cost)
                });
            }).catch(function(){
                self.spinner.tendersCostSubscription=false;
            });
        },

        getCostSubscriptionFor1Mbit(){
            let self=this;

            self.spinner.tendersCostSubscriptionFor1Mbit=true;

            self.datacollectionCostSubscriptionFor1Mbit.labels=[];
            self.datacollectionCostSubscriptionFor1Mbit.datasets[0].data = [];
            self.datacollectionCostSubscriptionFor1Mbit.datasets[1].data = [];
            self.datacollectionCostSubscriptionFor1Mbit.datasets[2].data = [];

            let link=`/api/statistics/monthly_payment/mbit`;

            link=self.setLinkQuery(link,'Subscription_Cost_For_1_Mbit');

            axios.get(link).then(function(response){
                self.spinner.tendersCostSubscriptionFor1Mbit=false;

                response.data.map(function (item){
                    self.datacollectionCostSubscriptionFor1Mbit.labels.push(item.region_name);
                    self.datacollectionCostSubscriptionFor1Mbit.datasets[0].data.push(item.min_cost);
                    self.datacollectionCostSubscriptionFor1Mbit.datasets[1].data.push(item.max_cost);
                    self.datacollectionCostSubscriptionFor1Mbit.datasets[2].data.push(item.avg_cost);
                });
            }).catch(function (){
                self.spinner.tendersCostSubscriptionFor1Mbit=false;
            });
        },

        getTendersStatus(){
            let self = this;
            self.spinner.tendersDatacolection=true;
            let link='';

            let sum_open_tenders=0;
            let sum_validation_tenders=0;
            self.summarized=0;

            let labelColors = {
                "Closed": "rgba(255, 5, 5, 0.7)",
                "ClosedWithoutWinner": "rgba(142, 142, 142,0.7)",
                "CloseWithResult": "rgba(37, 218, 106,0.7)",
            }

            self.datacollection.labels=[];
            self.datacollection.datasets[0].data=[];

            link=`/api/statistics/orders`;
            link = self.setLinkQuery(link,'Status_tenders');
            axios.get(link).then(function (response){
                self.spinner.tendersDatacolection=false;

                if(response.data === null){
                    self.datacollection.labels.push("Нічого не знайдено");
                    self.datacollection.datasets[0].data.push([0,0,0,0]);
                    return ;
                }

                self.tenderStatistic=response.data.map(function (item){
                    if (["SummarizeOrder", "ConfirmITRP", "OpenOrder", "ConfirmManager", "ConfirmMainOffice"].indexOf(item.status) !== -1){
                        sum_open_tenders += Number(item.count);
                        return
                    }
                    if (["CreatedStatus", "NetworkValidation", "ManagerValidation", "ResolveComments"].indexOf(item.status) !== -1){
                        sum_validation_tenders += Number(item.count);
                        return
                    }
                    self.summarized +=  Number(item.count)

                    self.datacollection.labels.push(self.dashboardTenders(item.status))
                    self.datacollection.datasets[0].data.push(item.count)
                    self.datacollection.datasets[0].backgroundColor.push(labelColors[item.status])
                });
                if (sum_open_tenders >= 0){
                    self.summarized +=  Number(sum_open_tenders)
                    self.datacollection.labels.push('У роботі')
                    self.datacollection.datasets[0].data.push(sum_open_tenders)
                    self.datacollection.datasets[0].backgroundColor.push('rgba(36, 159, 216,0.7)');
                }
                if (sum_validation_tenders >= 0){
                    self.summarized +=  Number(sum_validation_tenders)
                    self.datacollection.labels.push('На валідації')
                    self.datacollection.datasets[0].data.push(sum_validation_tenders)
                    self.datacollection.datasets[0].backgroundColor.push('rgba(214, 227, 90,0.7)');
                }
                // self.datacollection.labels=labels;
                // self.datacollection.datasets[0].data=count;
            }).catch(function (){self.spinner.tendersDatacolection=false;});
        },

        getCorrectionTenders(){
            let self=this;
            self.spinner.correctTendersDatacolection=true;
            self.datacollectionCorrectTenders.labels =[]
            self.datacollectionCorrectTenders.datasets[0].data =[]

            let link=`/api/statistics/quality`;
            link = self.setLinkQuery(link,'Number_of_corrected_tenders');

            axios.get(link).then(function (response) {
                    self.spinner.correctTendersDatacolection = false;
                    self.correctTenders = response.data;

                    self.datacollectionCorrectTenders.labels = [
                        '1 раз на доопрацюванні',
                        '2 рази на доопрацюванні',
                        'більше 2 разів на доопрацюванні',
                        'Якісні'
                    ];

                    self.datacollectionCorrectTenders.datasets[0].data = [
                        response.data.one,
                        response.data.two,
                        response.data.more_two,
                        response.data.quality,
                    ];
                }
            ).catch(function (){
                self.spinner.correctTendersDatacolection=false;
            });
        },

        fillData () {
            this.datacollection = {
                labels: [],
                cutoutPercentage:90,
                options:{
                    legend:{
                        display:true,
                        position:'right'
                    }
                },
                datasets: [
                    {
                        borderWidth: 1,
                        backgroundColor: [],
                        // backgroundColor: ['#ea3a3a','#8e8e8e','#75af26','#3a9eea','#5ac482'],
                        data: [],
                    },
                ]
            };
            this.datacollectionCorrectTenders = {
                labels: [],
                cutoutPercentage:90,
                options:{
                    legend:{
                        display:true,
                        position:'right'
                    }
                },
                datasets: [
                    {
                        borderWidth: 1,
                        backgroundColor: ['rgba(234, 234, 58,0.7)','rgba(234, 134, 58,0.7)','rgba(234, 58, 58,0.7)','rgba(117, 175, 38,0.7)'],
                        data: [],
                    },
                ]
            };
            this.datacollectionNumberProviders = {
                labels: [],
                options:{
                    title:{
                        display:false,
                    },
                    legend:{
                        display:false,
                    },
                    scales: {
                        yAxes: [
                            {ticks : {
                                    min : 0,
                                    stepSize: 1,
                                    reverse: false,
                                    beginAtZero: true
                                }},
                        ]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },
                datasets: [
                    {
                        borderWidth: 1,
                        backgroundColor: ['rgba(234, 136, 79, 0.7)','rgba(232, 180, 78, 0.7)','rgba(229, 204, 78, 0.7)','rgba(226, 222, 79, 0.7)','rgba(212, 224, 78, 0.7)','rgba(185, 221, 77, 0.7)','rgba(157, 219, 76, 0.7)','rgba(99, 216, 75, 0.7)','rgba(74, 214, 107, 0.7)','rgba(74, 211, 138, 0.7)','rgba(75, 209, 191, 0.7)','rgba(74, 193, 206, 0.7)','rgba(73, 162, 204, 0.7)','rgba(73, 136, 204, 0.7)','rgba(80, 94, 244, 0.7)','rgba(80, 132, 244, 0.7)','rgba(80, 146, 244, 0.7)','rgba(80, 192, 244, 0.7)','rgba(80, 211, 244, 0.7)','rgba(80, 231, 244, 0.7)','rgba(80, 244, 244, 0.7)','rgba(80, 244, 225, 0.7)','rgba(79, 242, 198, 0.7)','rgba(79, 239, 164, 0.7)','rgba(80, 237, 127, 0.7)'],
                        data: [],
                    },
                ]
            };
            this.datacollectionProviderActivity = {
                labels: [],
                options: {
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                fontSize: 9
                            }
                        }],
                        yAxes: [
                            {
                                ticks : {min : 0},
                                stacked: false,
                                beginAtZero: true,
                            }
                        ]
                    }
                },
                datasets: [
                    {
                        borderWidth: 0,
                        label: 'Всі заявки провайдера',
                        backgroundColor: 'rgba(191, 191, 191,0.8)',
                        minBarLength: 1,
                        data: [],
                        order: 3
                    },
                    {
                        borderWidth: 0,
                        label: 'Переміг у тендерах',
                        backgroundColor: 'rgba(117, 175, 38,0.8)',
                        minBarLength: 1,
                        data: [],
                        order: 2
                    },
                    {
                        borderWidth: 0,
                        label: 'Немає технічної можливості',
                        // backgroundColor: 'rgba(255, 255, 29, 0.7)',
                        backgroundColor: 'rgba(255, 210, 10,.5)',
                        minBarLength: 1,
                        data: [],
                        order: 1
                    },
                ]
            };
            this.datacollectionCostConnection={
                labels: [],
                options: {
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [ {
                            ticks : {min : 0},
                            stacked: false,
                            beginAtZero: true
                        }]
                    }
                },
                datasets: [
                    {
                        borderWidth: 0,
                        label: 'Мінімальна вартість',
                        backgroundColor: 'rgba(117, 175, 38,0.7)',
                        minBarLength: 1,
                        data: [],
                        order: 2
                    },
                    {
                        borderWidth: 0,
                        label: 'Максимальна вартість',
                        backgroundColor: 'rgba(234, 58, 58,0.8)',
                        minBarLength: 1,
                        data: [],
                        order: 3
                    },
                    {
                        label: 'Середня вартість',
                        borderColor: 'rgb(73, 136, 204)',
                        minBarLength: 1,
                        data: [],
                        type: 'line',
                        fill: false,
                        order: 1
                    },
                ]
            };
            this.datacollectionCostSubscription={
                labels: [],
                options: {
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            ticks : {min : 0},
                            stacked: false,
                            beginAtZero: true
                        }]
                    }
                },
                datasets: [
                    {
                        label: 'Мінімальна вартість',
                        backgroundColor: 'rgba(117, 175, 38,0.7)',
                        minBarLength: 1,
                        data: [],
                        order: 2
                    },
                    {
                        label: 'Максимальна вартість',
                        backgroundColor: 'rgba(234, 58, 58,0.7)',
                        minBarLength: 1,
                        data: [],
                        order: 3
                    },
                    {
                        label: 'Середня вартість',
                        backgroundColor: 'rgba(73, 136, 204, 0.7)',
                        borderColor: 'rgba(73, 136, 204, 0.7)',
                        minBarLength: 1,
                        data: [],
                        type: 'line',
                        fill: false,
                        order: 1
                    },
                ]
            };
            this.datacollectionCostSubscriptionFor1Mbit={
                labels: [],
                options: {
                    title: {
                        display: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    responsive: true,
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            ticks : {min : 0},
                            stacked: false,
                            beginAtZero: true
                        }]
                    }
                },
                datasets: [
                    {
                        label: 'Мінімальна вартість',
                        backgroundColor: 'rgba(117, 175, 38,0.7)',
                        minBarLength: 1,
                        data: [],
                        order: 2
                    },
                    {
                        label: 'Максимальна вартість',
                        backgroundColor: 'rgba(234, 58, 58,0.7)',
                        minBarLength: 1,
                        data: [],
                        order: 3
                    },
                    {
                        label: 'Середня вартість',
                        backgroundColor: 'rgba(73, 136, 204, 0.7)',
                        borderColor: 'rgba(73, 136, 204, 0.7)',
                        minBarLength: 1,
                        data: [],
                        type: 'line',
                        fill: false,
                        order: 1
                    },
                ]
            };
        },

    },
    watch:{
        'dashboard.consumer_type':function () {
            return this.getTendersStatus();
        },
        'dashboard.channel_purpose':function () {
            if (this.dashboard.consumer_type != 'Внутрішній (відділення)'){
                this.dashboard.consumer_type='';
            }
            return this.getTendersStatus();
        },
        'dashboard.dashboard.region.id':function () {
            return this.getTendersStatus();
        },
        'dashboardNew.channel_purpose':function (){
            if (this.dashboardNew.consumer_type != 'Внутрішній (відділення)'){
                this.dashboardNew.consumer_type='';
            }
            return this.getCostGrafics();
        },
        'dashboardNew.consumer_type':function (){
            return this.getCostGrafics();
        },
        'dashboardNew.connect_type':function (){
            return this.getCostGrafics();
        },
    }
}
</script>