import {can} from "@/rules/permissions";

export default {
    data() {
        return {
            privatbankStatus: {
                'OpenOrder': 'Опублікована',
                'SummarizeOrder': 'Підведення підсумків',
                'Created': 'На затверджені',
                'ResolveComments': 'Доопрацювання заявником',
                'ManagerValidation': 'Валідація Менеджером',
                'NetworkValidation': 'Валідація Інженером',
                'ConfirmITRP': 'Узгодження результатів ІТРП',
                'ConfirmMainOffice': 'Узгодження результатів ГО',
                'ConfirmManager': 'Затверждення результатів',
                'Closed': 'Тендер відмінений',
                'ClosedWithoutWinner': 'Тендер не відбувся',
                'CloseWithResult': 'Закритий з переможцем',
            },
            privatbankStatusColor: {
                'OpenOrder': 'success',
                'SummarizeOrder': 'info',
                'Created': 'warning',
                'ResolveComments': 'danger',
                'ManagerValidation': 'dark',
                'NetworkValidation': 'dark',
                'ConfirmITRP': 'primary',
                'ConfirmMainOffice': 'primary',
                'ConfirmManager': 'primary',
                'Closed': 'dark',
                'ClosedWithoutWinner': 'dark',
                'CloseWithResult': 'success',
            },
            providerStatus: {
                'OpenOrder': 'Прийом пропозицій',
                'SummarizeOrder': 'Підведення підсумків',
                'ConfirmITRP': 'Підведення підсумків',
                'ConfirmManager': 'Підведення підсумків',
                'ConfirmResult': 'Підведення підсумків',
                'ConfirmMainOffice': 'Підведення підсумків',
                'Closed': 'Тендер відмінений',
                'ClosedWithoutWinner': 'Тендер не відбувся',
                'CloseWithResult': 'Закритий з переможцем',
            },
            providerStatusColor: {
                'OpenOrder': 'success',
                'SummarizeOrder': 'info',
                'ConfirmITRP': 'info',
                'ConfirmManager': 'info',
                'ConfirmResult': 'info',
                'ConfirmMainOffice': 'info',
                'Closed': 'dark',
                'ClosedWithoutWinner': 'dark',
                'CloseWithResult': 'dark',
            },
            providerPropositionStatus: {
                'ProposalSubmitted': 'Пропозиція надіслана',
                'Winner': 'Переможець',
                'Participated': 'Брав участь',
                'NoTechnicalPossibility': 'Немає технічної можливості',
                'Refusal': 'Не виконав підключення',
            },
            providerPropositionStatusColor: {
                'ProposalSubmitted': 'success',
                'Winner': 'success',
                'Participated': 'warning',
                'NoTechnicalPossibility': 'warning',
                'Refusal': 'dark',
            },
            dashboardTendersS: {
                'OpenOrder': 'Відкриті',
                'Closed': 'Відмінений',
                'ClosedWithoutWinner': 'Не відбувся',
                'CloseWithResult': 'З переможцем',
            },
        }
    },
    methods: {
        can,
        convertTel(tel) {
            //let lenPhone = tel.length;
            let tt = tel.split('');

            let amount = tel.length - 10;

            // if(lenPhone == 10){
            //     tt.splice(0,"", " (");
            //     tt.splice(4,"", ") ");
            //     tt.splice(8,"", "-");
            //     tt.splice(11,"", "-");
            // }else if(lenPhone == 12){
            //     tt.splice(2,"", " (");
            //     tt.splice(6,"", ") ");
            //     tt.splice(10,"", "-");
            //     tt.splice(13,"", "-");
            // }else if(lenPhone == 13){
            //     tt.splice(3,"", " (");
            //     tt.splice(7,"", ") ");
            //     tt.splice(11,"", "-");
            //     tt.splice(14,"", "-");
            // }

            tt.splice(amount + 0, "", " (");
            tt.splice(amount + 4, "", ") ");
            tt.splice(amount + 8, "", "-");
            tt.splice(amount + 11, "", "-");

            tel = tt.join('');
            return tel;
        },
        dashboardTenders(status) {
            if (can('bank_user')) {
                return this.dashboardTendersS[status] || status;
            }
            return;
        },
        statusTender(status) {

            if (can('bank_user')) {
                return this.privatbankStatus[status] || status;
            } else {
                return this.providerStatus[status] || status;
            }
        },
        getBadgeStatus(status) {
            if (can('bank_user')) {
                return this.privatbankStatusColor[status] || status;
            } else {
                return this.providerStatusColor[status] || status;
            }
        },
        statusOrders(status) {
            return this.providerPropositionStatus[status] || status;
        },
        getBadgeStatusOrder(status) {
            return this.providerPropositionStatusColor[status];
        },
    }

}



