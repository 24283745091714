import {can} from "@/rules/permissions";
export default {
    data(){
        return{
            shortName:{
                'Товариство з обмеженою відповідальністю':'ТОВ',
                'Фізична особа — підприємець':'ФОП',
                'Приватне підприємство':'ПП',
                'Приватна компанія':'ПП',
                'Приватна фірма':'ПП',
                'Публічно-приватне партнерство':'ППП',
                'Відкрите акціонерне товариство':'ВАТ',
                'Закрите акціонерне товариство':'ЗАТ',
            },
            longRolesName:{
                aa:'Заявник',
                ac:'Керівник',
                mg:'Менеджер',
                vd:'Валідатор',
                ad:'Адміністратор',
                zm:'Менеджер відділу закупівель',
                za:'Адміністратор відділу закупівель',
            },
        }
    },
    methods:{
        can,
        shortNameProvider(name){
            for (const [key, value] of Object.entries(this.shortName)) {

                let re = new RegExp(key, "i");
                name = name.replace(re, value);

            }

            return name
        },
        longRolesBankUser(role){
            if (can('bank_user')){
                return this.longRolesName[role] || role;
            }
        }
    }
}